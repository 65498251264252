var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.$route.name != 'report')?_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-tabs',{attrs:{"align":"center"}},_vm._l((_vm.filterLabels),function(header,index){return _c('b-tab',{key:index,attrs:{"title":header.label},on:{"click":function($event){return _vm.switchFilter(header.value)}}})}),1)],1)],1):_vm._e(),_c('div',{staticClass:"my-1 d-flex justify-content-between align-items-center"},[_c('b-button',{staticClass:"box-shadow-1 px-5",attrs:{"variant":"gradient-primary","disabled":!this.rows.length},on:{"click":function($event){return _vm.DownloadAllDO()}}},[_vm._v("Download All Delivery Order")]),_c('b-form',{attrs:{"inline":""}},[_c('flat-pickr',{staticClass:"form-control font-small-2 w-auto mr-1",attrs:{"config":{
          dateFormat: 'Y-m-d',
          defaultDate: 'today',
          maxDate: _vm.dateFilterTo,
        },"placeholder":"Choose Date"},on:{"input":function($event){return _vm.getOrders()}},model:{value:(_vm.dateFilterFrom),callback:function ($$v) {_vm.dateFilterFrom=$$v},expression:"dateFilterFrom"}}),_c('span',{staticClass:"mr-1"},[_vm._v(" to ")]),_c('flat-pickr',{staticClass:"form-control font-small-2 w-auto",attrs:{"config":{
          dateFormat: 'Y-m-d',
          defaultDate: 'today',
          minDate: _vm.dateFilterFrom,
        },"placeholder":"Choose Date"},on:{"input":function($event){return _vm.getOrders()}},model:{value:(_vm.dateFilterTo),callback:function ($$v) {_vm.dateFilterTo=$$v},expression:"dateFilterTo"}})],1)],1),_c('b-card',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.type),expression:"type"}],staticClass:"form-control form-select rounded text-primary border-primary px-2 w-25",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.type=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.handleExport(_vm.type)}]}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Export")]),_c('option',{attrs:{"value":"csv"}},[_vm._v("CSV")]),_c('option',{attrs:{"value":"excel"}},[_vm._v("Excel")])]),_c('MyTable',{attrs:{"rows":_vm.rows,"columns":_vm.columns,"is-loading":_vm.isLoading,"checkbox-enabled":true},on:{"updateOrder":_vm.getOrders},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var props = ref.props;
return [(props.column.field === 'id')?_c('div',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.handleModal('order-summary', props.row)}}},[_vm._v(" "+_vm._s(props.row.id)+" ")])],1):(props.column.field === 'delivery_date')?_c('div',[_vm._v(" "+_vm._s(_vm.$helpers.formatDate(props.row.delivery_date))+" ")]):(props.column.field == 'status')?_c('div',[(props.row.status === 'Active')?_c('button',{staticClass:"btn btn-block Active",attrs:{"block":""}},[_vm._v(" "+_vm._s(props.row.status)+" ")]):(props.row.status === 'Assigned')?_c('button',{staticClass:"btn btn-block Assigned",attrs:{"block":""}},[_vm._v(" "+_vm._s(props.row.status)+" ")]):_vm._e(),(props.row.status === 'In Warehouse')?_c('button',{staticClass:"btn btn-block InWarehouse",attrs:{"block":""}},[_vm._v(" "+_vm._s(props.row.status)+" ")]):(props.row.status === 'In Transit')?_c('button',{staticClass:"btn btn-block InTransit",attrs:{"block":""}},[_vm._v(" "+_vm._s(props.row.status)+" ")]):_vm._e(),(props.row.status === 'Out For Delivery')?_c('button',{staticClass:"btn btn-block OutForDelivery",attrs:{"block":""}},[_vm._v(" "+_vm._s(props.row.status)+" ")]):(props.row.status === 'Delivered')?_c('button',{staticClass:"btn btn-block Delivered",attrs:{"block":""}},[_vm._v(" "+_vm._s(props.row.status)+" ")]):(
              props.row.status === 'Close Store' ||
              props.row.status === 'Assigned For Last Mile' ||
              props.row.status === 'Arrived At Branch'
            )?_c('button',{staticClass:"btn btn-block CloseStoreDeliveryFailed",attrs:{"block":""}},[_vm._v(" "+_vm._s(props.row.status)+" ")]):(props.row.status === 'Second Delivery')?_c('button',{staticClass:"btn btn-block SecondDelivery",attrs:{"block":""}},[_vm._v(" "+_vm._s(props.row.status)+" ")]):(props.row.status === 'Cancelled')?_c('b-button',{attrs:{"block":"","variant":"danger"}},[_vm._v(" "+_vm._s(props.row.status)+" ")]):(props.row.status === 'Generated')?_c('b-button',{attrs:{"block":"","variant":"warning"}},[_vm._v(" Generated ")]):_vm._e()],1):(props.column.field === 'action')?_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.DownloadDO(props.row.id)}}},[_c('span',[_vm._v("Download DO")])])],1)],1):_c('div',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1),_c('b-modal',{ref:"modal",attrs:{"title":_vm.modal.title,"size":"xl","hide-footer":"","no-close-on-backdrop":""}},[(_vm.modal.type === 'order-summary')?_c('DistributorOrderSummary',{attrs:{"order":_vm.modal.data}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }